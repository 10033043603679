jQuery(document).ready(function ($) {

    AOS.init({
        'once': true
    });

    $('.video-popup-link').magnificPopup({
        disableOn: 0,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false
    });

    $('.bio-popup-link').magnificPopup({
        type:'inline',
        midClick: true 
       
    });

    jQuery('.col-twins').matchHeight({
        byRow: true,
        property: 'height',
        target: null,
        remove: false
    });

    jQuery('.inner_card').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
    });
    jQuery('.visionaries_card').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
    });
 
    jQuery('.col-menu').matchHeight({
        byRow: false,
        property: 'height',
        target: jQuery('#site-logo'),
        remove: false
    });


    // MOBILE MENU TOGGLE
    function animateMenu(element, action) {
        var $this = element;
        jQuery(".col_hamburger_wrapper").toggleClass("is-active");
        
        if(action == '') {
            jQuery("#side_menu_wrapper").toggleClass('open');
            if(!$this.hasClass("closed")) {

            
                jQuery(jQuery("#mobile-menu li").get().reverse()).each(function(i) { 
                    TweenLite.to(jQuery(this), 0.2, {delay: 0.1 * i, ease: Power4.easeInOut, opacity:0, y: "-10%"});
                });
                TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, height:0, paddingTop: 0 });
                TweenLite.to(jQuery("#side_menu_wrapper .col_social ul li"), 0.5, {ease: Power4.easeInOut, opacity: 0});
                jQuery('#hamburger-icon-close').addClass("closed");
                jQuery("#hamburger-icon").addClass("closed");
                jQuery("body").removeClass("menu-open");
            } else {

                jQuery("#mobile-menu li").each(function(i) {
                    TweenLite.to(jQuery(this), 0.4, {delay: 0.2, ease: Power4.easeInOut, opacity: 1, y: "0%"});
                });
                TweenLite.set(jQuery("#side_menu_wrapper"), {ease: Power4.easeInOut, height:"auto", paddingTop: $('header.banner').outerHeight()});
                TweenLite.from(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, height:0, paddingTop: 0});
                TweenLite.to(jQuery("#side_menu_wrapper .col_social ul li"), 0.5, {delay: 0.3, ease: Power4.easeInOut, opacity: 1});

                jQuery("#hamburger-icon-close").removeClass("closed");
                jQuery("#hamburger-icon").removeClass("closed");
                jQuery("body").addClass("menu-open");
            }
        } else if(action == 'close') {
            jQuery("#side_menu_wrapper").removeClass('open');
            jQuery(jQuery("#mobile-menu li").get().reverse()).each(function(i) { 
                TweenLite.to(jQuery(this), 0.2, {delay: 0.1 * i, ease: Power4.easeInOut, opacity:0, y: "-10%"});
            });
            TweenLite.to(jQuery("#side_menu_wrapper"), 0.5, {ease: Power4.easeInOut, height:0, paddingTop: 0 });
            TweenLite.to(jQuery("#side_menu_wrapper .col_social ul li"), 0.5, {ease: Power4.easeInOut, opacity: 0 });

            jQuery('#hamburger-icon-close').addClass("closed");
            jQuery("#hamburger-icon").addClass("closed");
            jQuery("body").removeClass("menu-open");
        }
    }

    jQuery('#hamburger-icon-close').click(function() {
        animateMenu(jQuery(this), '');
    });

    jQuery("#hamburger-icon").click(function() {
        animateMenu(jQuery(this), '');
    });

    jQuery("#hamburger-icon-sticky").click(function() {
        animateMenu(jQuery(this), '');
    });

    jQuery('#mobile-menu a').click(function(e) {
        animateMenu(jQuery(this), '');
    });

    // mobile menu scrolling
    jQuery('#scrollable-wrapper').slimScroll({
        height: '100vh',
        alwaysVisible: false
    });

    jQuery(window).scroll(function() {
        if (jQuery(this).scrollTop() > 200) {
            jQuery('#sticky-header').addClass('visible');
        } else {
            jQuery('#sticky-header').removeClass('visible');
        }
    });
});